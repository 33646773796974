import React from 'react'
import { graphql } from 'gatsby'
import { Helmet, useTranslation, Trans } from 'gatsby-plugin-react-i18next'

import Layout from '../components/layout-contact'
import PageHeader from '../components/page-header'
import Drift from '../components/waypoint-drift'
import HubspotContactForm from '../components/hubspot-contact-form'

require('../scss/contact.scss')

function ContactPage({ data }) {
  const offices = data.allPrismicOffices.edges[0].node.data.office
  const [formSuccess, setFormSuccess] = React.useState(false)

  function formHandler() {
    setFormSuccess(true)
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }

  const [t, { language }] = useTranslation(['translations', 'seo'])

  const heroStyle = { height: '200px' }

  return (
    <Layout>
      <Helmet
        title={t('HomePage.title', { ns: 'seo' })}
        meta={[
          {
            name: 'description',
            content: t('HomePage.description', { ns: 'seo' }),
          },
          {
            name: 'keywords',
            content: t('HomePage.keywords', { ns: 'seo' }),
          },
          {
            property: 'og:title',
            content: t('HomePage.ogTitle', { ns: 'seo' }),
          },
          {
            property: 'og:description',
            content: t('HomePage.ogDescription', { ns: 'seo' }),
          },
          {
            property: 'og:image',
            content: 'https://versett.com/images/social-index.jpg',
          },
          { property: 'og:url', content: 'https://versett.com/contact/' },
          { name: 'twitter:card', content: 'summary_large_image' },
          { property: 'og:site_name', content: 'Versett' },
          { name: 'twitter:image:alt', content: 'Versett' },
        ]}
      >
        <html lang={language} className="s-contact-main" />
      </Helmet>
      {/*<section className="s-container s-page__hero s-page__hero--contact t-section-contact grid--contact contact-main">*/}
      <section
        style={heroStyle}
        className="s-container s-page__hero s-page__hero--short"
      >
        <PageHeader
          lede={!formSuccess ? t('ContactPage.lede') : t('ContactPage.altLede')}
          subheading={
            !formSuccess
              ? t('ContactPage.heading')
              : t('ContactPage.altHeading')
          }
        >
          {/* <div className="info" style={{ marginTop: '2rem' }}> */}

          {/* {!formSuccess && <p>{t('ContactPage.inquiriesSubheading')}</p>} */}
          {/* </div> */}
        </PageHeader>
      </section>
      {/* {!formSuccess && <HubspotContactForm />} */}
      <section className="s-container">
        <div className="contact-info" style={{ marginTop: 0 }}>
          {/* <p>{t('ContactPage.inquiriesTitle')}</p> */}
          <h2 className="title">{t('ContactPage.inquiriesHeading')}</h2>
          <p>
            <Trans
              values={{
                email: t('ContactPage.email'),
                phone: t('ContactPage.phone'),
              }}
              i18nKey="ContactPage.inquiriesData"
            >
              Email us at <a href={t('ContactPage.mailTo')}>email</a> or qwe
              <a href={t('ContactPage.phoneTo')}>phone</a>
            </Trans>
          </p>
          <div className="grid grid--4col" />
          <div className="grid grid--4col offices">
            {offices.map((office, index) => {
              const email = office.email || 'hello@versett.com'
              const href = `mailto:${email}`

              return (
                <a href={href} key={index} target="_blank">
                  <picture>
                    <img
                      src={`/images/office-0${index + 1}.jpg`}
                      alt={`${office.name} office`}
                    />
                  </picture>
                  <address className="address">
                    <div className="address__name">{office.name}</div>
                    <div className="address__street">{office.street}</div>
                    <div className="address__city">
                      {office.city}, {office.province_state}
                    </div>
                    <div className="address__postal">
                      {office.postal_code} {office.country}
                    </div>
                  </address>
                </a>
              )
            })}
          </div>
        </div>
      </section>
      <Drift />
    </Layout>
  )
}

export const query = graphql`
  query ContactQueries($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allPrismicOffices(filter: { lang: { eq: $language } }) {
      edges {
        node {
          data {
            office {
              name
              street
              city
              province_state
              postal_code
              country
              email
            }
          }
        }
      }
    }
  }
`

export default ContactPage
