import React, { Component } from 'react'
import _ from 'lodash'
import fetch from 'isomorphic-unfetch';
import Cookies from 'js-cookie';
import { withTranslation } from 'gatsby-plugin-react-i18next'
import { locations } from "./locations";
class HubspotContactForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      success: false,
      error: false,
      firstname: "",
      lastname: "",
      email: "",
      company: "",
      country_dropdown: "",
      industry_dropdown: "",
      message: "",
      errors: {
        firstname: '',
        lastname: '',
        email: '',
        company: '',
        country: '',
        industry: '',
        message: ''
      }
    }
  }

  render() {
    const { t } = this.props

    const heroColour = `#121212`

    const heroStyle = { backgroundColor: heroColour, "paddingTop": 0,  "paddingBottom": 0}

    const secondaryColour = `white`

    const sectionStyle = { backgroundColor: secondaryColour, color: `#121212` }

    // Versett hubspot for production
    const portalId = '5164796'
    const formId = 'd1429396-72d9-434e-a5bc-35c9ef1c1d69'

    // Rodrigo Peters personal hubspot for tesing only
    // const portalId = "22152867";
    // const formId = "a53d85f4-2a23-4dcd-bd4a-dd8d05c8c00d";

    //form validation
    const validateForm = (errors) => {
      let valid = true;
      Object.values(errors).forEach(
        (val) => val.length === 0 && (valid = false)
      );
      return valid;
    }

    const handleChange = (event) => {
      event.preventDefault();
      this.setState({showConsent: true})
      const { name, value } = event.target;
      let errors = this.state.errors;

      switch (name) {
        case 'firstname':
          errors.firstname =
            value.length === 0
              ? 'Please complete this required field.'
              : '';
          break;
        case 'lastname':
          errors.lastname =
            value.length === 0
              ? 'Please complete this required field.'
              : '';
          break;
        case 'email':
          errors.email =
            value.length === 0
              ? 'Please complete this required field.'
              : '';
          break;
        case 'company':
          errors.company =
            value.length === 0
              ? 'Please complete this required field.'
              : '';
          break;
        case 'country_dropdown':
          errors.country =
            value.length === 0
              ? 'Please complete this required field.'
              : '';
          break;
        case 'industry_dropdown':
          errors.industry =
            value.length === 0
              ? 'Please complete this required field.'
              : '';
          break;
        case 'message':
          errors.message =
            value.length === 0
              ? 'Please complete this required field.'
              : '';
          break;
        default:
          break;
      }
      this.setState({errors, [name]: value})
      this.setState({[name]: event.target.value})
    }

    const {errors} = this.state;

    const submitForm = e => {
      if (e) e.preventDefault();
      const firstName = this.state.firstname;
      const lastName = this.state.lastname;
      const emailAddress = this.state.email;
      const company = this.state.company;
      const country = this.state.country_dropdown;
      const industry = this.state.industry_dropdown;
      const message = this.state.message;
      const requiredCompleted = firstName !== ""  && lastName !== "" && emailAddress !== "" && company !== "" && country !== "" && industry !== "" && message != "";

      if (firstName === "") {
        this.setState({errors: { ...this.state.errors, firstname: 'Please complete this required field.' }})
      } else if (lastName === "") {
        this.setState({errors: { ...this.state.errors, lastname: 'Please complete this required field.' }})
      } else if (emailAddress === "") {
        this.setState({errors: { ...this.state.errors, email: 'Please complete this required field.' }})
      } else if (company === "") {
        this.setState({errors: { ...this.state.errors, company: 'Please complete this required field.' }})
      } else if (country === "") {
        this.setState({errors: { ...this.state.errors, country: 'Please complete this required field.' }})
      } else if (industry === "") {
        this.setState({errors: { ...this.state.errors, industry: 'Please complete this required field.' }})
      } else if (message === "") {
        this.setState({errors: { ...this.state.errors, message: 'Please complete this required field.' }})
      } else {
        this.setState({errors: { ...this.state.errors, email: '', firstname: '', lastname: '', company: '', country: '', industry: '', message: '' }})
      }

      if(!validateForm(this.state.errors) && requiredCompleted) {
        const isBrowser = typeof window !== 'undefined';
        const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
        const pageUri = isBrowser ? window.location.href : null;
        const pageName = isBrowser ? document.title : null;
        const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

        this.setState({
          loading: true,
        })

        const body = {
          submittedAt: Date.now(),
            fields:[
              {
                name: 'firstname',
                value: this.state.firstname,
              },
              {
                name: 'lastname',
                value: this.state.lastname,
              },
              {
                name: 'email',
                value: this.state.email,
              },
              {
                name: 'company',
                value: this.state.company
              },
              {
                name: 'country_dropdown',
                value: this.state.country_dropdown
              },
              {
                name: 'industry_dropdown_new',
                value: this.state.industry_dropdown
              },
              {
                name: 'message',
                value: this.state.message
              },
            ],
              context: {
              hutk,
              pageUri,
              pageName,
          }
        }

        fetch(postUrl, {
          method: 'post',
          body: JSON.stringify(body),
          headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json, application/xml, text/plain, text/html, *.*',
          }),
        })
          .then(res => res.json())
          .then(() => {
            this.setState({
              success: true,
              error: false,
              loading: false,
              firstname: "",
              lastname: "",
              email: "",
              company: "",
              country: "",
              industry: "",
              message: "",
            })
          })
          .catch(err => {
            this.setState({
              success: false,
              error: err,
              loading: false,
            })
          });
      }
    };

    return (
      <section style={heroStyle} className="s-container s-page__hero resources grid grid--2col-2-1">
        <div className='resource-hero-container'>
        { this.state.success ?
          <h4>{t('ContactPage.ThankYouForm')}</h4>
          :
          <form
            data-form-id={formId}
            data-portal-id={portalId}
            disabled={this.state.loading}
            onSubmit={submitForm}
            className="hubspot-form"
          >
            <fieldset className="form-fieldset">
              <div className="grid grid--2col-1-1">
                <div className="input-container">
                  <label className="form-label" htmlFor="firstname">
                    First Name*
                  </label>
                  <input
                    name="firstname"
                    className="form-input"
                    type="text"
                    value={this.state.firstname}
                    onChange={(e) => {handleChange(e)}}
                    onBlur={(e) => {handleChange(e)}}
                    required
                  />
                  {errors.firstname.length > 0 && <div className='error'>{this.state.errors.firstname}</div>}
                </div>
                <div className="input-container">
                  <label className="form-label" htmlFor="lastname">
                    Last Name*
                  </label>
                  <input
                    name="lastname"
                    className="form-input"
                    type="text"
                    value={this.state.lastname}
                    onChange={(e) => {handleChange(e)}}
                    onBlur={(e) => {handleChange(e)}}
                    required
                  />
                  {errors.lastname.length > 0 && <div className='error'>{errors.lastname}</div>}
                </div>
              </div>
              <div className="input-container">
                <label className="form-label" htmlFor="email">
                    Email*
                </label>
                <input
                  name="email"
                  className="form-input"
                  type="text"
                  value={this.state.email}
                  onChange={(e) => {handleChange(e)}}
                  onBlur={(e) => {handleChange(e)}}
                  required
                />
                {errors.email.length > 0 && <div className='error'>{errors.email}</div>}
              </div>
              <div className="input-container">
                <label className="form-label" htmlFor="company">
                Company Name*
                </label>
                <input
                  name="company"
                  className="form-input"
                  type="text"
                  value={this.state.company}
                  onChange={(e) => {handleChange(e)}}
                  onBlur={(e) => {handleChange(e)}}
                  required
                />
                {errors.company.length > 0 && <div className='error'>{errors.company}</div>}
              </div>
              <div className="grid grid--2col-1-1">
                <div className="input-container">
                  <div className="label--icon">
                    <label className="form-label" htmlFor="country_dropdown">
                      Region/Country*
                    </label>
                  </div>
                  <select
                    className="form-input"
                    name="country_dropdown"
                    label="country"
                    value={this.state.country_dropdown}
                    onChange={(e) => {handleChange(e)}}
                    onBlur={(e) => {handleChange(e)}}
                    required
                  >
                    <option value=""></option>
                      {locations.map((country, i) => <option value={country} key={i}>{country}</option>)}
                  </select>
                  {errors.country.length > 0 && <div className='error'>{errors.country}</div>}
                </div>
                <div className="input-container">
                  <div className="label--icon">
                    <label className="form-label" htmlFor="industry">
                      Company Industry*
                    </label>
                  </div>
                <select
                    className="form-input"
                    name="industry_dropdown"
                    label="industry"
                    value={this.state.industry_dropdown}
                    onChange={(e) => {handleChange(e)}}
                    onBlur={(e) => {handleChange(e)}}
                    required
                  >
                    <option value=""></option>
                    <option value="Finance">Finance</option>
                    <option value="Retail">Retail</option>
                    <option value="Travel">Travel</option>
                    <option value="Higher Education">Higher Education</option>
                    <option value="Government">Government</option>
                    <option value="Non-Profit">Higher Education</option>
                    <option value="Other">Other</option>                  </select>
                  {errors.industry.length > 0 && <div className='error'>{errors.industry}</div>}
                </div>
              </div>
              <div className="input-container">
                <label className="form-label" htmlFor=" message">
                    Message*
                </label>
                <textarea
                  rows="4"
                  name="message"
                  className="form-textinput"
                  value={this.state.message}
                  onChange={(e) => {handleChange(e)}}
                  onBlur={(e) => {handleChange(e)}}
                  required
                />
                {errors.message.length > 0 && <div className='error'>{errors.message}</div>}
              </div>
            </fieldset>
            <div>
              <button
                className="button button--outline"
                type="submit"
                onClick={submitForm}
              >
                Submit
              </button>
            </div>
          </form>
        }
        </div>
      </section>
    )
  }
}

export default withTranslation('translations')(HubspotContactForm)
