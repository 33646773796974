import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Topbar from '../Topbar'
import Footer from '../footer'

require('../../scss/style.scss')

require('focus-visible')

const Layout = ({ children, startingClass = 'color-switch--black' }) => (
  <StaticQuery
    query={graphql`
      query ContactLayoutQueries {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div id="vrst-main" className={startingClass}>
        <Topbar />
        <main>{children}</main>
        <Footer compact={true} />
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
